// ---------------
// Custom Variables / Theme
// ---------------

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #fc6978;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$primary--main: #3a619f;
$primary--alt: #335687;

$primary: #fff;
$secondary: #032b47;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$body-color: #fff;

//Custom vars
$dark-blue: #141526;
$header-height: 80px;
$header-height--small: 45px;

// ---------------
// Bootstrap Variables
// ---------------

$font-family-sans-serif: 'PP Neue Montreal', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

$headings-font-family: 'PP Neue Montreal', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

$offcanvas-padding-y: 2.3rem;
$offcanvas-padding-x: 2.3rem;
$border-width: 2px;
$border-radius: 0.5rem;
$border-radius-lg: $border-radius;
$spacer: 1rem;
$carousel-control-icon-width: 3rem;
$dropdown-dark-bg: $primary--main;
$body-bg: $dark-blue;
$root-base: 1rem;
$font-size-base: 1.125rem;
$link-color: #fff;
$component-active-bg: $primary--main;

$font-weight-base: 300;

$h1-font-size: $root-base * 5.625;
$h2-font-size: $root-base * 3.75;
$h3-font-size: $root-base * 2;
$h4-font-size: $root-base * 1.56;
$h5-font-size: $root-base * 0.875;
$h6-font-size: $root-base * 0.625;

$form-check-input-bg: $primary--main;
$form-check-input-border: 1px solid rgba($primary--main, 0.25);

$btn-border-radius: 1.5rem;
$btn-border-radius-sm: 1.5rem;
$btn-border-radius-lg: 2rem;

$btn-font-size: 0.875rem;

$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 1.3rem;
$btn-font-size-lg: 0.875rem;

$input-bg: transparent;
$input-font-size-lg: 1rem;
$input-disabled-bg: transparent;

$form-select-bg: transparent;
$form-select-font-size-lg: 1rem;
$form-select-disabled-color: $gray-600;
$form-select-disabled-bg: transparent;

$text-muted: $gray-400;
$small-font-size: 14px;

$font-weight-bold: 500;
$font-weight-bolder: 700;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  // laptop
  xl: 1280px,
  xxl: 1800px,
  // desktop
);

$chime-grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1800px,
);

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 1,
  ),
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 3.5,
  ),
  9: (
    $spacer * 4,
  ),
  10: (
    $spacer * 4.5,
  ),
  11: (
    $spacer * 5,
  ),
  12: (
    $spacer * 6,
  ),
  13: (
    $spacer * 8,
  ),
  14: (
    $spacer * 10,
  ),
  15: (
    $spacer * 12,
  ),
  16: (
    $spacer * 16,
  ),
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'dark-blue': $dark-blue,
  'body': $body-color,
  'black-70': rgba($black, 0.7),
  'primary--main': $primary--main,
  'primary--alt': $primary--alt,
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$input-placeholder-color: $gray-500;

@each $name, $value in $grays {
  .text-gray-#{$name} {
    color: $value !important;
  }
}

@mixin absolute-100 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Chime app
$chime-action: $primary--main;
$chime-action--variant-1: lighten($chime-action, 35%);
$chime-action--variant-2: lighten($chime-action, 60%);
$chime-page-bg: transparent;
$chime-secondary-bg: $primary--alt;
$chime-volume-fill: #fff;
$chime-text-color: #fff;
$chime-success: #39b54a;
$chime-floating-roaster-bg: #55616d;

$lg-max: 1250px;
$med-max: 1024px;
$sm-max: 768px;
$xs-max: 479px;

$lg-min: $lg-max + 1;
$med-min: $med-max + 1;
$sm-min: $sm-max + 1;
$xs-min: $xs-max + 1;

$headline-color: hsl(0, 0%, 18%);

@mixin video-player-color($color) {
  .theo-primary-color,
  .vjs-selected {
    color: $color !important;
  }

  .theo-primary-background {
    color: #000000 !important;
    background-color: $color !important;
  }
}

$chime-action: #c2192a !default;
$chime-action--variant-1: lighten(#c2192a, 35%) !default;
$chime-action--variant-2: lighten(#c2192a, 60%) !default;
$chime-page-bg: #191a1e !default;
$chime-secondary-bg: #5b5c61 !default;
$chime-volume-fill: #fff !default;
$chime-success: #39b54a !default;
$chime-error: #ff0000 !default;
$chime-floating-roaster-bg: #5b5c61 !default;
$chime-meeting-height: 100vw !default;
$chime-text-color: #ebebeb !default;
$chime-tile-bg: #27282e !default;

$chime-tooltip-text-color: white;
$chime-tooltip-background-color: black;
$chime-tooltip-margin: 1px;
$chime-tooltip-arrow-size: 6px;

$chime-grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$chime-thumb-scrollbar-1: #979aa0;
$chime-thumb-scrollbar-2: #f3f4f5;

.account-sidemenu {
  position: sticky;
  top: 70px;
  display: none !important;

  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}

.account-sidemenu a {
  text-decoration: none;
}

.account-sidemenu__inner {
  position: absolute;
  min-width: 240px;
  right: 5%;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.account-sidemenu ul {
  padding-left: 0;
  padding-top: 18px;
  list-style: none;
}

.account-sidemenu li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.account-sidemenu li {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-size: 12px;
  font-weight: bold;
  padding-top: 1.2rem;
  padding-bottom: 1.1rem;
  text-transform: uppercase;
}

.account-sidemenu__item--selected {
  pointer-events: none;
  &:after {
    position: absolute;
    content: '\2B24';
    right: 1px;
    font-size: 10px;
  }
}

.account-sidemenu__glow--selected {
  position: absolute;
  width: 65%;
  height: 100%;
  bottom: -77%;
  border-radius: 60%;
  background-color: #3a619f;
  opacity: 0.7;
  margin-left: 17%;
  filter: blur(8px);
}
