.modal-backdrop {
  top: $header-height;
  height: calc(100vh - #{$header-height}) !important;
  background-color: transparent !important;
  opacity: 0 !important;
  transition: 0.2s all ease-in;
}

.body--header-small .modal-backdrop {
  top: $header-height--small;
  height: calc(100vh - #{$header-height--small}) !important;
}

.modal-header .close {
  cursor: pointer;
}

.modal-content {
  padding: 1rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 0;
}

.modal-header,
.modal-footer {
  border: none;
  padding-bottom: 0;
}

.modal-body {
  p {
    color: #fff;
  }
}

.modal-header {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  .btn-close {
    display: none;
    box-sizing: border-box;
    width: 0.75rem;
    height: 0.75rem;
    margin: 0;
  }
}

.modal-footer {
  .modal-button {
    background: $dark-blue;
    color: #fff;
  }
}

.modal-backdrop-open {
  opacity: 1 !important;
  backdrop-filter: blur(15px) !important;
}

.chime-modal {
  backdrop-filter: blur(15px) !important;
}
